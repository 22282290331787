import BaseTextComponent from "@/components/base/el/BaseTextComponent";

export default {
    name: "BaseSection",
    mixins: [BaseTextComponent],
    data() {
        return {
            tag: 'section',
            is_text: false
        }
    },
}
